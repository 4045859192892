import React from "react"
import { FooterUI } from "./Search.css"

function PoweredBy() {
  return (
    <FooterUI>
      <small>
        Powered by{` `}
        <a href="https://algolia.com" target="_blank" rel="noopener noreferrer">
          Algolia
        </a>
      </small>
    </FooterUI>
  )
}

export default PoweredBy
