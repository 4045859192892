import React from "react"
import { Index, Hits, connectStateResults } from "react-instantsearch-dom"

import { useSearchContext } from "./Search.Context"
import * as hitComps from "./HitComps"
import { EmptyUI } from "./Search.css"

function ResultList(props) {
  const { searchIndex, setFocus } = useSearchContext()

  return (
    <>
      {searchIndex.map(({ name, title, hitComp }) => (
        <Index key={name} indexName={name}>
          <header>
            <h3>{title}</h3>
            <Stats />
          </header>
          <Results>
            <Hits hitComponent={hitComps[hitComp](() => setFocus(false))} />
          </Results>
        </Index>
      ))}
    </>
  )
}

const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) => {
    const hasResults = res && res.nbHits > 0

    return hasResults ? (
      children
    ) : (
      <EmptyUI>No results for '{state.query}'</EmptyUI>
    )
  }
)

const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`
)

export default ResultList
