import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO"
import Post from "../components/Post"
import Layout from "../components/Layout"
import Pagination from "../components/Pagination"

class BlogIndex extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const { currentPage } = pageContext

    const seoTitle = currentPage > 1 ? `Page ${currentPage}` : null

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={seoTitle} />
        {posts.map(({ node }) => {
          return <Post key={node.fields.slug} {...node} />
        })}
        <Pagination pageContext={pageContext} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            image {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
