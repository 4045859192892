import React, { useCallback } from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { useSearchContext } from "./Search.Context"

import { InputUI } from "./Search.css"

export default connectSearchBox(({ refine, ...rest }) => {
  const { setFocus } = useSearchContext()
  const handleOnFocus = useCallback(() => setFocus(true), [])
  const handleOnChange = useCallback(event => refine(event.target.value), [
    refine,
  ])

  const handleOnSubmit = useCallback(event => event.preventDefault(), [])

  return (
    <form onSubmit={handleOnSubmit}>
      <InputUI
        type="text"
        placeholder="Search"
        aria-label="Search"
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        {...rest}
      />
    </form>
  )
})
