import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"

export const Image = props => {
  const { align, src, title } = props

  return (
    <FeaturedImageUI align={align}>
      {typeof src === "string" ? (
        <PlainImage src={src} title={title} alt={title} />
      ) : (
        <Img fluid={src} title={title} alt={title} />
      )}
    </FeaturedImageUI>
  )
}

Image.defaultProps = {
  src: {},
}

const FeaturedImageUI = styled("div")`
  margin-bottom: 2em;

  ${({ align }) =>
    align === "center" &&
    `
    margin: auto;
  `}
`

const PlainImage = styled("img")`
  max-width: 100%;
  height: auto;
  display: block;
  margin: auto;
`

function Container(props) {
  const { src, ...rest } = props

  const trueSrc = typeof src === "string" ? src : src.childImageSharp.fluid

  return <Image src={trueSrc} {...rest} />
}

export default Container
