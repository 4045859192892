import React from "react"
import { Link } from "gatsby"
import Container from "./Container"
import Search from "./Search"
import styled from "@emotion/styled"

function Header(props) {
  const { location, title } = props
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1>
        <Link to={`/`}>{title}</Link>
      </h1>
    )
  } else {
    header = (
      <h3>
        <Link to={`/`}>{title}</Link>
      </h3>
    )
  }

  return (
    <header>
      <SiteTitle>{header}</SiteTitle>
    </header>
  )
}

export const SiteTitle = styled("div")`
  * {
    font-size: 1.2rem;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const TopBar = styled("div")`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 30px 0 20px;
`

function SiteHeader(props) {
  return (
    <Container>
      <TopBar>
        <Header {...props} />
        <Search />
      </TopBar>
    </Container>
  )
}

export default SiteHeader
