import styled from "@emotion/styled"

export const RootUI = styled("div")`
  position: relative;
`

export const HitsWrapperUI = styled("div")`
  -webkit-overflow-scrolling: touch;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  right: 0;
  max-height: 400px;
  max-width: 30em;
  overflow-y: auto;
  padding: 0.7em 1em 0.4em;
  position: absolute;
  top: calc(100% + 0.5em);
  width: 80vw;
  z-index: 2;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    border-bottom: 1px solid #eee;
    display: block;

    &:last-child {
      border-bottom: none;
    }
  }

  li a {
    color: #0055ff;
    display: block;
    padding: 10px 0;

    &:hover {
      background: #f5f5f5;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3em;
  }

  h3 {
    margin: 0 0 0.5em;
  }

  h4 {
    margin: 0;
  }
`

export const InputUI = styled("input")`
  border: 1px solid #ccc;
  padding: 2px;
  max-width: 160px;
  width: 100%;
`

export const FooterUI = styled("div")`
  color: #999;
  padding: 10px 0 5px;
  text-align: right;

  a {
    color: black;
    text-decoration: none;
  }

  small {
    font-size: 70%;
  }
`

export const EmptyUI = styled("div")`
  color: #777;
  padding: 10px 0;
`
