import React from "react"
import Container from "./Container"
import styled from "@emotion/styled"

function SiteFooter() {
  return (
    <footer>
      <FooterUI>
        <Container>
          <small>© {new Date().getFullYear()}, WeDrawDraws</small>
        </Container>
      </FooterUI>
    </footer>
  )
}

export const FooterUI = styled("div")`
  padding: 3em 0;
`

export default SiteFooter
