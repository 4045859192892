import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

function Pagination(props) {
  const { isFirst, isLast, prevPage, nextPage } = props

  return (
    <ListUI>
      {!isFirst && (
        <ItemUI className="prev">
          <Link to={prevPage} rel="prev" title="previous">
            ←
          </Link>
        </ItemUI>
      )}
      {!isLast && (
        <ItemUI className="next">
          <Link to={nextPage} rel="next" title="next">
            →
          </Link>
        </ItemUI>
      )}
    </ListUI>
  )
}

const ListUI = styled("div")`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 1em;
  margin-top: -1em;
`
const ItemUI = styled("div")`
  flex: 1;
  font-size: 2rem;
  margin: 0 -20px;

  &.next {
    text-align: right;
  }

  a {
    color: inherit;
    display: block;
    padding: 20px;
    text-decoration: none;

    &:hover {
      opacity: 0.5;
    }
  }
`

function Container(props) {
  const { pageContext } = props
  const { currentPage, numPages } = pageContext

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/" : `page/${(currentPage - 1).toString()}`
  const nextPage = `page/${(currentPage + 1).toString()}`

  const componentProps = {
    isFirst,
    isLast,
    prevPage,
    nextPage,
  }

  return <Pagination {...componentProps} />
}

export default Container
