import React from "react"
import Container from "./Container"
import SiteFooter from "./SiteFooter"
import SiteHeader from "./SiteHeader"

function Layout(props) {
  const { location, title, children } = props

  return (
    <>
      <SiteHeader location={location} title={title} />
      <main>
        <Container>{children}</Container>
      </main>
      <SiteFooter />
    </>
  )
}

export default Layout
