import styled from "@emotion/styled"

const PostTitle = styled("div")`
  padding: 1em 0;

  * {
    font-size: 2rem;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 3rem;
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export default PostTitle
