import React, { useState, createRef } from "react"

import { SearchContext } from "./Search.Context"

import Input from "./Input"
import Dropdown from "./Dropdown"
import ResultList from "./ResultList"
import SearchClient from "./SearchClient"

import { useClickOutside } from "./Search.utils"

const searchIndex = [{ name: `Posts`, title: `DrawDraws`, hitComp: `PostHit` }]

function Search({ searchIndex, collapse }) {
  const searchRef = createRef()
  const [query, setQuery] = useState(``)
  const [focus, setFocus] = useState(false)

  useClickOutside(searchRef, () => setFocus(false))

  const showResults = query.length > 0 && focus

  const contextProps = {
    focus,
    query,
    searchIndex,
    searchRef,
    setFocus,
    setQuery,
    showResults,
  }

  return (
    <SearchContext.Provider value={contextProps}>
      <SearchClient>
        <Input collapse={collapse} focus={focus.toString()} />
        <Dropdown>
          <ResultList />
        </Dropdown>
      </SearchClient>
    </SearchContext.Provider>
  )
}

Search.defaultProps = {
  collapse: "true",
  searchIndex: searchIndex,
}

export default Search
