import React from "react"
import { Link } from "gatsby"
import PostTitle from "../components/PostTitle"
import Image from "../components/Image"
import styled from "@emotion/styled"

function Post(props) {
  const { content, image, slug, title } = props

  return (
    <PostUI>
      <PostTitle>
        <h2>
          <Link to={slug}>{title}</Link>
        </h2>
      </PostTitle>
      <Link to={slug}>
        <Image align="center" src={image} title={title} />
      </Link>
      <p
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </PostUI>
  )
}

function Container(props) {
  const { excerpt, fields, frontmatter, ...rest } = props
  const { slug } = fields
  const { description, date, image, title: frontmatterTitle } = frontmatter

  const title = frontmatterTitle || slug

  const componentProps = {
    ...rest,
    content: description || excerpt,
    date,
    image,
    slug,
    title,
  }

  return <Post {...componentProps} />
}

export const PostUI = styled("div")`
  border-bottom: 1px solid #eee;
  margin: 2em 0 4em;
  padding: 0 0 3em;

  &:last-child {
    border-bottom: none;
  }
`

export default Container
