import React from "react"
import { InstantSearch } from "react-instantsearch-dom"
import { useSearchContext } from "./Search.Context"
import { searchClient } from "./Search.utils"

import { RootUI } from "./Search.css"

export function SearchClient(props) {
  const { children } = props
  const { searchRef, setQuery, searchIndex } = useSearchContext()

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={searchIndex[0].name}
      onSearchStateChange={({ query }) => setQuery(query)}
      root={{ Root: RootUI, props: { ref: searchRef } }}
    >
      {children}
    </InstantSearch>
  )
}

export default SearchClient
