import React from "react"
import { useSearchContext } from "./Search.Context"
import PoweredBy from "./PoweredBy"
import { HitsWrapperUI } from "./Search.css"

function Dropdown(props) {
  const { children } = props
  const { showResults } = useSearchContext()

  const style = { display: showResults ? "block" : "none" }

  return (
    <HitsWrapperUI style={style}>
      {children}
      <PoweredBy />
    </HitsWrapperUI>
  )
}

export default Dropdown
